import {
  Avatar,
  Divider,
  Link,
  // FormControlLabel,
  Grid,
  IconButton,
  Stack,
  SwipeableDrawer,
  // Switch,
  Typography,
  useTheme,
  Button,
  Box
} from '@mui/material';
import { default as PersonIcon } from '../../../assets/dashboard/main/person_icon.svg';
import { default as CancelIcon } from '../../../assets/dashboard/main/cancel_icon.svg';
import { default as MailIcon } from '../../../assets/dashboard/main/mail_icon.svg';
import { default as PhoneIcon } from '../../../assets/dashboard/main/phone_icon_v1.svg';
import { default as LockIcon } from '../../../assets/dashboard/main/lock_icon.svg';
//import Button from "@mui/material/Button";
import { useGetUserProfileQuery } from '../../../store/api/auth/api';
import { useDispatch } from 'react-redux';
import { setPageMode } from './reducer/slice';
import { useTranslation } from 'react-i18next';

interface IProfile {
  changeDrawerStatus: (newDrawerStatus: boolean) => void;
  drawerStatus: boolean;
}

const ProfileView = (props: IProfile) => {
  const { changeDrawerStatus, drawerStatus } = props;

  const dispatch = useDispatch();

  const { t } = useTranslation();
  const { data: userProfile } = useGetUserProfileQuery();

  const toggleDrawer = () => {
    changeDrawerStatus(!drawerStatus);
  };

  return (
    <Grid borderRadius="5px" width="625px" padding="32px" role="presentation" height="100%">
      <Grid xs={12} item height="100%">
        <Stack direction="row" justifyContent="space-between" marginBottom="13px" alignItems="center">
          <Stack direction="row" spacing="13px" alignItems="center">
            <Typography color={'#002E6D'} variant="h6">
              {t('profile')}
            </Typography>{' '}
          </Stack>
          <IconButton disableRipple onClick={toggleDrawer}>
            <img src={CancelIcon} alt="Logout Icon" />
          </IconButton>
        </Stack>
        <Stack direction="row" justifyContent="flex-start" marginBottom="4px" alignItems="center">
          <Avatar
            sx={{ width: '80px', height: '80px', border: '4px solid white', boxShadow: '0px 3px 6px #00000029' }}
          />
          <Stack>
            <Button
              sx={{
                marginLeft: '6px',
                maxWidth: '100px',
                textTransform: 'none',
                fontWeight: 'normal',
                color: '#002E6D',
                // textDecoration: "underline",
                backgroundColor: 'transparent',
                border: '1px solid transparent', // Kenarlık yok
                '&:hover': {
                  backgroundColor: 'transparent !important', // Hover durumunda arka plan rengi değişmez
                  color: 'inherit !important' // Hover durumunda metin rengi değişmez
                },
                '&:focus': {
                  outline: 'none' // Odaklandığında odak çerçevesini kaldırır
                },
                '&:active': {
                  border: '1px solid transparent' // Tıklama durumunda kenarlık yok
                }
              }}
            >
              <Typography sx={{ opacity: '60%', fontWeight: 400, fontSize: 15 }}>{t('change')}</Typography>{' '}
            </Button>
          </Stack>
        </Stack>

        <Divider sx={{ marginTop: '32px', marginBottom: '10px' }} />
        <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'flex-start'}>
          <Button
            variant="text"
            sx={{
              maxWidth: '90px',
              textTransform: 'none',
              fontWeight: 'normal',
              color: '#002E6D',
              // textDecoration: "underline",
              backgroundColor: 'transparent',
              border: '1px solid transparent', // Kenarlık yok
              '&:hover': {
                backgroundColor: 'transparent !important', // Hover durumunda arka plan rengi değişmez
                color: 'inherit !important' // Hover durumunda metin rengi değişmez
              },
              '&:focus': {
                outline: 'none' // Odaklandığında odak çerçevesini kaldırır
              },
              '&:active': {
                border: '1px solid transparent' // Tıklama durumunda kenarlık yok
              },
              pb: '0 !important'
            }}
            onClick={() => dispatch(setPageMode('profileEdit'))}
          >
            <Typography sx={{ opacity: '60%', fontWeight: 400, fontSize: 15 }}>{t('edit')}</Typography>{' '}
          </Button>
        </Stack>
        <Stack
          direction="row"
          // justifyContent="space-between"
          pt="14px"
        >
          <Grid xs={5}>
            <Typography sx={{ fontSize: 16 }}>
              <IconButton size="small" aria-label="error" disabled sx={{ marginBottom: 1, marginRight: 1 }}>
                <img src={PersonIcon} alt="MailIcon" style={{ maxHeight: '24px' }} />
              </IconButton>
              {t('fullName')}
            </Typography>
          </Grid>
          <Grid pt={1}>
            <Typography variant="h6"> {userProfile?.name}</Typography>
          </Grid>
        </Stack>
        <Stack direction="row" pt="20px">
          <Grid xs={5}>
            <Typography sx={{ fontSize: 16 }}>
              <IconButton size="small" aria-label="error" disabled sx={{ marginBottom: 1, marginRight: 1 }}>
                <img src={MailIcon} alt="MailIcon" style={{ maxHeight: '24px' }} />
              </IconButton>
              {t('emailProfileScreen')}
            </Typography>
          </Grid>
          <Grid pt={1}>
            <Typography variant="h6">{userProfile?.email}</Typography>
          </Grid>
        </Stack>
        <Stack direction="row" pt="20px">
          <Grid xs={5}>
            <Typography sx={{ fontSize: 16 }}>
              <IconButton size="small" aria-label="error" disabled sx={{ marginBottom: 1, marginRight: 1 }}>
                <img src={PhoneIcon} alt="MailIcon" style={{ maxHeight: '24px' }} />
              </IconButton>
              {t('phoneNumber')}
            </Typography>
          </Grid>
          <Grid pt={1}>
            <Typography variant="h6">{'+' + userProfile?.phone}</Typography>
          </Grid>
        </Stack>
        <Divider sx={{ marginTop: '32px', marginBottom: '16px' }} />
        <Stack direction="row" alignItems="center">
          <Grid>
            <Button
              variant="text"
              sx={{
                whiteSpace: 'nowrap',
                textTransform: 'none',
                padding: '0px !important',
                fontWeight: 'normal',
                color: '#002E6D',
                backgroundColor: 'transparent',
                border: '1px solid transparent', // Kenarlık yok
                '&:hover': {
                  backgroundColor: 'transparent !important', // Hover durumunda arka plan rengi değişmez
                  color: 'inherit !important' // Hover durumunda metin rengi değişmez
                },
                '&:focus': {
                  outline: 'none' // Odaklandığında odak çerçevesini kaldırır
                },
                '&:active': {
                  border: '1px solid transparent' // Tıklama durumunda kenarlık yok
                },
                pb: '0 !important'
              }}
              onClick={() => dispatch(setPageMode('passwordChange'))}
              disableRipple
            >
              <IconButton size="small" disabled>
                <img src={LockIcon} alt="MailIcon" style={{ maxHeight: '24px' }} />
              </IconButton>
              <Typography sx={{ fontSize: 16, fontWeight: 400 }}>{t('changePassword')}</Typography>
            </Button>
          </Grid>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ProfileView;
